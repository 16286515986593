<template>
  <v-row class="d-flex align-center pa-0 ma-0 login-row">
    <v-col
      cols="12"
      md="6"
      class="pa-0 d-none d-sm-none d-md-flex login-left fill-height"
    >
      <div class="wrapper d-flex flex-column" style="z-index: 1">
        <v-row>
          <img
            src="@/assets/login/gns-software-logo-white.svg"
            alt="GNS Software Logo"
            width="250px"
          />
        </v-row>
        <v-row class="d-flex align-center">
          <v-col cols="auto">
            <h1>
              Bienvenidos
              <br />a Recibos Digitales
            </h1>
            <h3>Gestioná tus recibos de sueldo en un sólo lugar.</h3>
          </v-col>
        </v-row>
      </div>
      <div class="overlay"></div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      class="pa-0 py-10 white login-right"
      v-show="showreset==1"
    >
      <v-row
        class="wrapper d-flex flex-column flex-nowrap justify-center px-8 px-sm-10"
        style="max-height: 1000px"
      >
        <div>
          <img
            src="@/assets/login/gns-software-logo.svg"
            alt="GNS Software Logo"
            class="d-flex d-md-none mb-6 logo"
          />
          <v-row class="d-flex align-center">
            <v-col class="col-12 col-sm-auto">
              <h1 class="inicio-sesion my-0">
                Inicio de sesión
                <div class="left-color-bar"></div>
              </h1>
            </v-col>

            <v-col class="col text-sm-right"> </v-col>
          </v-row>
        </div>

        <v-form autocomplete="off" class="login-form mt-10 mb-4">
          <v-text-field
            label="Documento de identidad"
            v-model="login.username"
            :rules="login.usernameRules"
            name="username"
            required
            v-on:keyup.enter="processLogin()"
            v-on:blur="loadEmpresas()"
            class="login-input"
            dense
          />

          <v-select
            ref="idEmpresa"
            v-model="login.empresa"
            :loading="login.empresasLoading"
            :disabled="login.empresasLoading"
            :items="login.empresas"
            color="primary"
            label="Empresa"
            item-text="Nombre"
            item-value="CodigoEmp"
            :rules="login.empresaRules"
            class="login-input"
          ></v-select>

          <v-text-field
            v-model="login.password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="login.passwordRules"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            label="Contraseña"
            counter
            @click:append="showPassword = !showPassword"
            v-on:keyup.enter="processLogin()"
            class="login-input"
          ></v-text-field>

          <v-row>
            <v-col cols="12" sm="12" class="text-left">
              <a
                href="javascript:void(0)"
                v-on:click="showreset = 2"
                class="link-pass"
                >¿Te olvidaste la contraseña?</a
              >
            </v-col>
          </v-row>
        </v-form>

        <!-- <div class="d-flex flex-flow flex-wrap justify-space-between mt-4">
          <v-checkbox v-model="checkbox" label="Mantener sesión iniciada" class="mt-0 pt-0"></v-checkbox>
        </div>-->

        <div class="d-flex flex-flow flex-wrap justify-space-between">
          <v-row>
            <v-col cols="12" xs="12" sm="12" class="text-right">
              <v-btn
                v-on:keyup.enter="processLogin()"
                v-on:click="processLogin()"
                :disabled="loading" 
                :loading="loading" 
                class="btn-ingresar"
                >Ingresar</v-btn
              >
            </v-col>
          </v-row>
        </div>

        <footer>
          <a href="javascript:void(0)" @click="openContactForm()">
            <i class="fas fa-envelope"></i>Contacto
          </a>
          <a href="https://admin.salarios.com.uy">
            <i class="fas fa-user-shield"></i>Ingreso administrador
          </a>
        </footer>
      </v-row>
    </v-col>

    <v-col cols="12" md="6" class="pa-0 white" v-show="showreset==2">
      <v-row class="wrapper d-flex flex-column justify-center pa-10">
        <img
          src="@/assets/login/gns-software-logo.svg"
          alt="GNS Software Logo"
          width="250px"
          class="d-flex d-md-none mb-6"
        />
        <h1 class="text-left secondary--text texto-bienvenida">
          <v-icon class="mr-5 pb-1">fas fa-lock</v-icon>Recuperar contraseña
        </h1>
        <p>
          Para iniciar el proceso de recuperación de contraseña ingrese sus
          datos
        </p>
        <v-form ref="resetForm" autocomplete="off" class="mt-4">
          <v-text-field
            label="Documento de identidad"
            v-model="reset.username"
            :rules="[rules.required]"
            name="username"
            required
            v-on:keyup.enter="processReset()"
            v-on:blur="loadResetEmpresas()"
            class="login-input"
            dense
          />

          <v-select
            ref="idEmpresa"
            v-model="reset.empresa"
            :loading="reset.empresasLoading"
            :disabled="reset.empresasLoading"
            :items="reset.empresas"
            color="primary"
            label="Empresa"
            item-text="Nombre"
            item-value="CodigoEmp"
            :rules="[rules.required]"
            class="login-input"
          ></v-select>
        </v-form>

        <div class="d-flex flex-flow flex-wrap justify-space-evenly mt-10">
          <v-btn 
            :loading="saving"
            :disabled="saving" 
            v-on:keyup.enter="processReset()"
            v-on:click="processReset()"
            class="btn-ingresar"
            rounded
            block
            large
            >Recuperar</v-btn
          >
        </div>

        <div class="d-flex flex-flow flex-wrap justify-space-between mt-10">
          <a
            href="javascript:void(0)"
            v-on:click="showreset = 1"
            class="secondary--text"
            style="text-decoration: none"
          >
            <v-icon class="mr-5">arrow_back</v-icon>Volver
          </a>
        </div>
      </v-row>
    </v-col>

    <v-col cols="12" md="6" class="pa-0 white" v-show="showreset==3">
      <v-row class="wrapper d-flex flex-column justify-center pa-10">
        <img
          src="@/assets/login/gns-software-logo.svg"
          alt="GNS Software Logo"
          width="250px"
          class="d-flex d-md-none mb-6"
        />
        <h1 class="text-left secondary--text texto-bienvenida">
          <v-icon class="mr-5 pb-1">fas fa-lock</v-icon>Reiniciar contraseña
        </h1>
        <p>
          Para reiniciar su contraseña complete los siguientes datos
        </p>
        <v-form ref="perfilPasswordForm" autocomplete="off">
          <v-text-field
            label="Contraseña" 
            :rules="[rules.required]"
            v-model="password.new_password"
            placeholder=" "
             
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" 
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
          ></v-text-field>

          <v-text-field 
            class="pt-3"
            label="Repetir contraseña"
            v-model="password.confirm_password"
            
            :rules="[rules.confirmation]"
            placeholder=" "
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" 
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
          ></v-text-field>

          <v-progress-linear v-if="password.new_password != ''"
            :value="progress"
            :color="color"
            height="7" 
            class="mt-2"
          ></v-progress-linear>
        </v-form>

        <div class="d-flex flex-flow flex-wrap justify-space-evenly mt-10">
          <v-btn 
            :loading="password.changing" 
            :disabled="password.changing" 
            v-on:keyup.enter="changePassword()"
            v-on:click="changePassword()"
            class="btn-ingresar"
            rounded
            block
            large
            >Cambiar</v-btn
          >
        </div>

        <div class="d-flex flex-flow flex-wrap justify-space-between mt-10">
          <a
            href="javascript:void(0)"
            v-on:click="showreset = 2"
            class="secondary--text"
            style="text-decoration: none"
          >
            <v-icon class="mr-5">arrow_back</v-icon>Volver
          </a>
        </div>
      </v-row>
    </v-col>    

    <v-dialog v-model="dialogControlCodigo" max-width="450">
      <v-card>
        <v-card-title class="font-weight-bold">
          Ingresar Código de Verificación
        </v-card-title>
        <v-card-subtitle class="font-weight-bold">Enviamos un código de verificación a tu teléfono +598 {{ mask_mobile }}.</v-card-subtitle>

        <v-card-text> 
          <v-form ref="codigoPasswordForm" autocomplete="off">
            <v-otp-input 
              v-model="codigo"
              :disabled="sendingCode"
              @finish="sendCode"
              length="6"
            ></v-otp-input>
            <v-overlay absolute :value="sendingCode">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-overlay>
            <v-row>
              <v-col cols="12" sm="12" class="text-left">
                Si no recibiste el código, podes reenviarlo. 
                <v-btn
                  text
                  :disabled="screen_time != null" 
                  :loading="user_reenviar_waap_loading" 
                  v-on:click="reenviarCodigoViaWaap()" 
                >
                  Reenviar código
                  <template v-slot:loader>
                    <span>Reenviando...</span>
                  </template>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" class="text-left">
                Si luego de reenviar el codigo, no lo recibes, podes probar enviando un 
                <v-btn
                  text
                  :disabled="screen_time != null" 
                  :loading="user_reenviar_sms_loading" 
                  v-on:click="reenviarCodigoViaSms()" 
                >
                  SMS
                  <template v-slot:loader>
                    <span>Reenviando...</span>
                  </template>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="screen_time != null">
              <v-col cols="12" sm="12" class="text-center">
                <code class="text-primary">
                  <v-icon class="mr-5 pb-1">fas fa-clock</v-icon>
                  {{ screen_time }} min
                </code>
              </v-col>
            </v-row>
          </v-form>          
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="grey darken-1"
            text
            @click="closeDialogCode()"
          >
            Cancelar
          </v-btn>
          <v-btn 
            :disabled="sendingCode"
            :loading="sendingCode"
            color="grey darken-1"
            text
            @click="sendCode()"
          >
            Verificar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogControl" max-width="450">
      <v-card>
        <v-card-title class="font-weight-bold">
          {{ dialogTitle }}
        </v-card-title>
        <v-card-subtitle class="font-weight-bold">{{
          dialogSubTitle
        }}</v-card-subtitle>

        <v-card-text v-html="dialogMessage"> </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="grey darken-1"
            text
            @click="dialogControl = !dialogControl"
          >
            Volver
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="$store.state.snackbar.show"
      :color="$store.state.snackbar.color"
      :timeout="$store.state.snackbar.timeout"
      ><div v-html="$store.state.snackbar.message"></div
    ></v-snackbar>

    <contacto-out :show-contact-in="contactoDialog" @contactFormClosed="contactoDialog=false"></contacto-out>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { requestResetPassword, requestSendCode, requestChangePassword, requestResendCode } from "@/api/auth";
import ContactoOut from "@/components/contacto/Contactoout.vue"

const TIME_LOCK = 120;

export default {
  name: "Login",
  components: {
    ContactoOut
  },

  data() {
    return {
      codigo: '',
      token: '',
      dialogControlCodigo: false,
      sendingCode: false,
      mask_mobile: '',

      tiempoRestante: null,
      intervaloTiempo: null,
      screen_time: null,
      user_reenviar_sms_loading: false,
      user_reenviar_waap_loading: false,
      user_verify_loading: false,
      user_mobile_loading: false,

      password: {
        codigo: '',
        changing: false,
        new_password: '',
        confirm_password: ''
      },
      showreset: 1,
      loading: false,
      redirect: undefined,
      showPassword: false,
      valid: false,

      login: {
        username: "",
        usernameRules: [(v) => !!v || "El usuario es requerido"],

        empresa: "",
        empresaRules: [(v) => !!v || "La empresa es requerida"],
        empresasLoading: false,
        empresas: [],

        password: "",
        passwordRules: [(v) => !!v || "El password es requerido"],
      },

      reset: {
        username: "",
        empresa: "",
        empresasLoading: false,
        empresas: [],
      },

      saving: false,

      rules: {
        required: (value) => !!value || "Requerido.",
        confirmation: () => {
          return (
            this.password.new_password == this.password.confirm_password ||
            "Las contraseñas no coinciden"
          );
        },
      },

      dialogControl: false,
      dialogTitle: "",
      dialogSubTitle: "",
      dialogMessage: "",
      contactoDialog: false,
      show1: false,
      show2: false
    };
  },

  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },

  computed: {
    ...mapGetters(["local_api_url", "user"]),

    progress () {
      if(this.password.new_password == null) return 0
      let strength = this.calculateStrength()
      return Math.min(100, strength * 100 / 5)
    },

    color () {
      return ['error', 'warning', 'success'][Math.floor(this.progress / 40)]
    },
  },

  created() {
    this.$store.commit("setSnackBar", { show: false, message: null });
  },

  methods: {
    closeDialogCode() {
      this.codigo = '';
      this.dialogControlCodigo = !this.dialogControlCodigo;
    },

    updateClock() {
        // Actualizar el tiempo restante
        this.tiempoRestante--;
        
        const minutos = Math.floor(this.tiempoRestante / 60);
        const segundos = this.tiempoRestante % 60;
        
        const segundosFormateados = segundos < 10 ? `0${segundos}` : segundos;
        

        // Mostrar el tiempo en la consola (puedes ajustar esto según tu necesidad)
        this.screen_time = `${minutos}:${segundosFormateados}`

        // Verificar si el tiempo ha llegado a 0
        if (this.tiempoRestante < 0) {
            this.tiempoRestante = null;
            this.screen_time = null;
            // Limpiar el intervalo para detener el contador
            clearInterval(this.intervaloTiempo);
        }
    },

    initClock() {
        this.tiempoRestante = TIME_LOCK;
        this.intervaloTiempo = setInterval(this.updateClock, 1000);
    },

    async reenviarCodigoViaSms() {
      this.user_reenviar_sms_loading = true;

      let params = {
        'token': this.token,
        'method': 'sms'
      }

      try {
        let data = await requestResendCode(params);
        let msg = 'Hemos reenviado el código de verificacion al teléfono ' + data.mask_mobile + '.';
        this.$store.commit("setSnackBar", {
            message: msg,
            show: true,
            color: "#008f39",
        });
        this.initClock();
      } catch (error) {
        this.showError(error)
      }
      this.user_reenviar_sms_loading = false;
    },

    async reenviarCodigoViaWaap() {
      this.user_reenviar_waap_loading = true;

      let params = {
        'token': this.token,
        'method': 'wasap'
      }

      try {
        let data = await requestResendCode(params);

        if(data.code != 200) {
          this.$store.commit("setSnackBar", {
            message: data.error,
            show: true,
            color: "#cc0605",
          });
          this.user_reenviar_waap_loading = false;

          return;
        }

        let msg = 'Hemos reenviado el código de verificacion al teléfono ' + data.mask_mobile + '.';
        this.$store.commit("setSnackBar", {
            message: msg,
            show: true,
            color: "#008f39",
        });
        this.initClock();
      } catch (error) {
        this.showError(error)
      }
      this.user_reenviar_waap_loading = false;
    },

    configResetBuildMessage(errors) {
      if (typeof errors === 'string') {
          return `${errors}`;
      } else if(Array.isArray(errors)) {
          return `${errors[0]}`;
      } else {
          var result = '<ul>';
          for (var i in errors) {
              result += `<li>${errors[i][0]}</li>`;
          }
          result += '</ul>';
          return result;
      }
    },

    /*generateCodeViaWaap() {
      if(this.user_mobile_loading) return false;
      let prefix = this.form.prefix;    
      let phone = this.form.mobile;
      let url = this.baseUrl + "/seguridad/php/configuracionesResetAdmin.php?accion=generateCodeViaWaap&phone="+phone+"&prefix="+prefix;

      this.user_mobile_loading = true;
      
      this.$http.post(url, {}).then(function (response) {
          let data = JSON.parse(response.body);
          this.user_mobile_loading = false;
          this.configResetAdminSetInputsStates();
          if (data.estado == "ERR") {
              showDanger('Error', this.configResetBuildMessage(data.mensaje));
          } else {
              this.mobile_number = '+' + prefix + ' ' + phone;
              this.configResetAdminShowVerificarModal();
              this.initClock();
          }
      }, error => {
          this.user_mobile_loading = false;
          this.configResetAdminSetInputsStates();
          showDanger('Error', "el servicio no esta disponible");
      });
    },*/

    async sendCode() {
      this.sendingCode = true;

      let params = {
        'code': this.codigo,
        'token': this.token
      }

      try {
        await requestSendCode(params);
        this.password.codigo = this.codigo;
        let msg = 'El código de verificacion ha sido validado correctamente. Ya puedes completar el formulario para crear tu nueva contraseña';
        this.$store.commit("setSnackBar", {
            message: msg,
            show: true,
            color: "#008f39",
            timeout: 10000
        });
        this.codigo = '';
        this.dialogControlCodigo = false;
        this.showreset = 3;
      } catch (e) {
        // this.dialogMessage = e.response.data.error;
        // this.dialogControl = true;
      }
      this.sendingCode = false;
    },

    calculateStrength() {
      let strength = 0;
      let password = this.password.new_password
      if (password.match(/[a-z]+/)) {
        strength += 1
      }
      if (password.match(/[A-Z]+/)) {
        strength += 1
      }
      if (password.match(/[0-9]+/)) {
        strength += 1
      }
      if (password.match(/[\W]+/)) {
        strength += 1
      }
      if (password.length > 6) {
        strength += 1
      }
      return strength
    },

    async changePassword() {
      if(this.$refs.perfilPasswordForm.validate(true)) {

        this.password.changing = true

        try {
          let params = {
            'code': this.password.codigo, 
            'token': this.token, 
            'password': this.password.new_password, 
            'password_confirmation': this.password.confirm_password
          };
          let data = await requestChangePassword(params);
          this.showreset = 1;
          this.$store.commit("setSnackBar", {
            message: data.message,
            show: true,
            color: "#008f39",
            timeout: 10000
        });
        } catch (error) {
          this.showError(error);
          this.password.changing = false;
          this.password.new_password = '';
          this.password.confirm_password = '';
        }

        this.password.changing = false;
      }
    },

    //this.local_api_url
    openContactForm() {
      this.contactoDialog = true
    },

    processLogin() {
      // const empresa = this.login.empresas.find(
      //   (e) => e.CodigoEmp == this.login.empresa
      // );

      let credentials = {
        username: this.login.username,
        empresa: this.login.empresa,
        password: this.login.password,
        //id_cliente: empresa ? empresa.idCli : null,
        //id_cliente_empresa: empresa ? empresa.idCliEmp : null
      };
      this.loading = true;
      this.$store
        .dispatch("user/login", {
          url: process.env.VUE_APP_MIX_BASE_API,
          userInfo: credentials,
        })
        .then(() => {
          this.$store.commit("user/SET_COMPANIES", this.login.empresas);
          this.$store.dispatch("dict/initCollections");
          if (this.user.estado == 0) {
            this.$router.push({ path: "perfil" });
          } else {
            this.$router.push({ path: this.redirect || "/" });
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.valid = false;
          this.showError(error)
        });
    },

    async processReset() {
      if (this.$refs.resetForm.validate(true)) {
        // const empresa = this.reset.empresas.find(
        //   (e) => e.CodigoEmp == this.reset.empresa
        // );
        let params = {
          username: this.reset.username,
          empresa: this.reset.empresa
        };

        this.saving = true;

        try {
          let data = await requestResetPassword(params);

          if(data.method == 'wasap') {
            if(data.code != 200) {
              this.$store.commit("setSnackBar", {
                message: data.error,
                show: true,
                color: "#cc0605",
              });
              this.saving = false;

              return;
            }

            this.token = data.token;
            this.dialogControlCodigo = true;
            this.mask_mobile = data.mask_mobile;
            this.initClock();
          }

          if(data.method == 'email') {
            this.dialogTitle = "Recuperación de contraseña enviada";
            this.dialogSubTitle = "Comprueba tu correo electrónico";

            if (data.mask_email) {
              this.dialogMessage =
                "A <b>" +
                data.mask_email +
                "</b> te hemos mandado unas instrucciones para completar el restablecimiento de tu contraseña.<br/><br/>Si no lo ves en la bandeja de entrada pasados unos minutos, echa un vistazo en la carpeta de spam.";
            } else {
              this.dialogTitle = "No se puede recuperar su contraseña";
              this.dialogSubTitle = "";
              this.dialogMessage =
                "No existe un e-mail asociado a su cuenta. Contactese con el departamento de Recursos Humanos de su empresa para asociarle uno!";
            }
            this.dialogControl = true;
          }

        } catch {
          this.dialogMessage =
            "Si el usuario se encuentra en nuestra base de datos recibirá un mail con las instrucciones para reestablecer la contraseña!";
          this.dialogControl = true;
        }

        /*this.$store.commit("setSnackBar", {
          message: "Si el usuario se encuentra en nuestra base de datos recibirá un mail con las instrucciones para reestablecer la contraseña!",
          show: true,
          color: "#008f39",
          timeout: 5000,
        })*/

        this.reset = {
          username: "",
          empresa: "",
          empresasLoading: false,
          empresas: [],
        };
        this.$refs.resetForm.resetValidation();

        this.saving = false;
      }
    },

    async loadEmpresas() {
      this.login.empresasLoading = true;
      try {
        const response = await axios.get(
          process.env.VUE_APP_MIX_BASE_API + "/datos-empresas",
          { params: { doc: this.login.username } }
        );
        this.login.empresas = response.data;
      } catch(error) {
        this.showError(error)
      }
      this.login.empresasLoading = false;
    },

    async loadResetEmpresas() {
      this.reset.empresasLoading = true;
      try {
        const response = await axios.get(
          process.env.VUE_APP_MIX_BASE_API + "/datos-empresas",
          { params: { doc: this.reset.username } }
        );
        this.reset.empresas = response.data;
      } catch(error) {
        this.showError(error)
      }
      this.reset.empresasLoading = false;
    },

    showError(error) {
      let error_data = error.response.data;
      let message = null;
      if (
        Object.prototype.hasOwnProperty.call(error_data, "code") &&
        Object.prototype.hasOwnProperty.call(error_data, "message")
      ) {
        message =
          error_data.message +
          (error_data.code != 1000 ? " | Código: " + error_data.code : "");
      } else if (error_data.errors) {
        let errors_messages = error_data.errors;
        message = "<ul>";
        for (let m in errors_messages) {
          message += "<li>" + errors_messages[m].join(" - ") + "</li>";
        }
        message += "</ul>";
      } else if (error_data.error) {
        message = error_data.error;
      } else {
        message =
          "Ha ocurrido un error en el servidor. Intenta conectarte nuevamente.";
      }

      // Mostrar un mensaje
      this.$store.commit("setSnackBar", {
        message: message,
        show: true,
        color: "#cc0605",
      });
    }
  },
};
</script>

<style scoped>
.wrapper {
  max-width: 550px;
  height: 100%;
  margin: 0 auto;
}

.login-left {
  background-image: url("../../assets/login/login-bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 80%;
  background-position-y: 50%;
  color: white;
  position: relative;
}

.login-left h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 47px;
  line-height: 97.3%;
  letter-spacing: -0.0243em;
  color: #ffffff;
}

.login-left h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 107.3%;
  letter-spacing: -0.0143em;
  color: #ffffff;
  max-width: 350px;
}

.inicio-sesion {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  display: flex;
  align-items: center;
  letter-spacing: -0.0143em;
  color: #2d2d2d;
  position: relative;
}

.login-right {
  position: relative;
  background-color: white !important;
  height: 100%;
  max-height: 650px;
}

.login-right .logo {
  width: 250px;
}
.login-right .left-color-bar {
  position: fixed;
  left: 50%;
  width: 14px;
  height: 170px;
  background-color: #ff3301;
}
.overlay {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) -4.63%,
    rgba(196, 196, 196, 0) 103.53%
  );
  opacity: 0.7;
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.btn-ingresar {
  background-color: #ff3301 !important;
  border-radius: 28px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff !important;
  box-shadow: none;
  width: 260px;
  height: 56px !important;
  text-transform: capitalize !important;
}

a {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #000000 !important;
}

.login-right .v-text-field .v-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #333333;
}

.login-right .v-input input {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  color: #757575;
  margin: 10px auto;
}

.login-row {
  background: white;
  height: 100%;
}

.v-snack--bottom {
  margin: auto 0;
}

.login-right footer {
  margin-top: 3rem;
  display: flex;
  flex-flow: column wrap;
}

.login-right footer a,
.link-pass {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #757575 !important;
  padding-bottom: 0.5rem;
}

.login-right footer a {
  text-decoration: none;
}

.login-right footer a:last-child {
  padding-bottom: 0;
}

.login-right footer a:hover,
.link-pass:hover {
  color: #2c2c2c !important;
  opacity: 1 !important;
}

.login-right footer a i {
  padding-right: 0.5rem;
  width: 29px;
}

@media (max-height: 700px) and (max-width: 991px) {
  .texto-bienvenida {
    display: none;
  }
}

@media (max-width: 960px) {
  .login-right .left-color-bar {
    left: 0;
  }
}

@media (max-width: 700px) {
  .login-right .left-color-bar {
    width: 6px;
    height: 80px;
  }
  .btn-ingresar {
    margin-top: 0 !important;
  }
}

@media (max-height: 850px) and (max-width: 991px) {
  .login-right footer {
    margin-top: 2rem;
  }
  .btn-ingresar {
    height: min-content !important;
    width: 100% !important;
    padding: 0.8rem !important;
  }
  .login-right .logo {
    max-width: 170px;
    max-height: 44px;
  }
  .inicio-sesion {
    font-size: 24px;
    line-height: 0;
  }
  .login-right .v-input input {
    margin: 0;
  }
  .btn-ingresar {
    margin-top: 0;
  }
  .login-right a {
    font-size: 14px;
  }
  .login-right {
    /* max-height: 500px; */
    /* overflow: hidden !important; */
    padding: 0;
  }
  .login-right .v-input {
    padding: 0 !important;
  }
  .login-righ > .row {
    padding: 4rem !important;
  }
  .login-row {
    display: flex !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0;
  }

  @media (max-height: 650px) and (max-width: 991px) {
    .login-form {
      margin-top: 1rem !important;
      margin-bottom: 0rem !important;
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
    .login-row {
      display: block !important;
    }
  }

  @media (max-height: 560px) and (max-width: 991px) {
    .login-right .logo {
      max-width: 130px;
      margin-bottom: 14px !important;
    }

    .inicio-sesion {
      font-size: 18px;
    }

    .login-form {
      margin-top: 1rem !important;
      margin-bottom: 0rem !important;
      padding-top: 0rem;
      padding-bottom: 0rem;
    }
    .btn-ingresar {
      padding: 0.6rem !important;
    }
    .login-right footer {
      margin-top: 1rem;
    }
  }
}
</style>
