<template>
  <div class="text-center">
    <v-dialog
      v-model="showContactIn"
      max-width="450"
      :persistent="false"
      @click:outside="closeDialog()"
    >
      <v-card>
        <v-card-title class="headline pb-3 mb-0">
          <span>Comunícate</span>
          <v-spacer></v-spacer>
          <v-btn x-small icon right @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-row justify="space-between">
            <v-col cols="12" md="12" class="pt-0">
              <v-form ref="contactoOutForm" autocomplete="off">

                <v-text-field
                    label="Documento de identidad"
                    v-model="contacto.documento"
                    :rules="contacto.documentoRules"
                    name="documento"
                    required
                    v-on:blur="loadEmpresas()"
                    class="login-input"
                    dense
                />

                <v-select 
                    ref="idContactoOutEmpresa"
                    v-model="contacto.empresa"
                    :loading="contacto.empresasLoading"
                    :items="contacto.empresas"
                    color="primary"
                    label="Empresa"
                    item-text="Nombre"
                    item-value="CodigoEmp"
                    :rules="contacto.empresaRules"
                    class="login-input"
                ></v-select>

                <v-text-field
                    label="Correo electrónico"
                    type="email"
                    v-model="contacto.email"
                    :rules="contacto.emailRules"
                    dense
                ></v-text-field>

                <v-text-field
                    v-model="contacto.celular"
                    :rules="contacto.celularRules"
                    type="number"
                    label="Celular"
                    dense
                ></v-text-field>

                <v-textarea
                    rows="5"
                    auto-grow
                    label="Mensaje"
                    :rules="contacto.mensajeRules"
                    v-model="contacto.mensaje"
                ></v-textarea>

              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            type="button"
            @click="send()"
            color="primary"
            class="btn mt-0 mb-6"
            small
            :disabled="sending"
            :loading="sending"
            >Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { sendPublicContact } from "@/api/core";
import axios from "axios";

export default {
  props: {
    showContactIn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      contacto: {
        documento: "",
        documentoRules: [(v) => !!v || "El documento de identidad es requerido"],

        empresa: "",
        empresaRules: [(v) => !!v || "La empresa es requerida"],
        empresasLoading: false,
        empresas: [],

        celular: "",
        celularRules: [(v) => !!v || "El celular o telefono de contacto es requerido"],

        email: "",
        emailRules: [ (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "El e-mail ingresado es incorrecto.";
        } ],

        mensaje: "",
        mensajeRules: [(v) => !!v || "La consulta es requerida"],
      },

      sending: false,
    };
  },

  created() {},

  computed: {

  },

  mounted() {

  },

  methods: {
    async send() {
      try {
        if (!this.$refs.contactoOutForm.validate()) {
            return false
        } 

        const empresa = this.contacto.empresas.find(
            (e) => e.CodigoEmp == this.contacto.empresa
        )
        let params = {
            documento: this.contacto.documento,
            cod_emp: this.contacto.empresa,
            celular: this.contacto.celular,
            email: this.contacto.email,
            mensaje: this.contacto.mensaje,
            empresa:  empresa.RazonSocial,
            id_cliente_empresa: empresa.idCliEmp
        };
        this.sending = true
        await sendPublicContact(params)
        this.sending = false
        this.cleanForm()
        this.$emit("contactFormClosed")

        this.$store.commit("setSnackBar", {
            message: 'Tú consulta ha sido enviada correctamente.',
            show: true,
            color: "#008f39",
        })
      } catch (e) {
        this.sending = false
      }
    },

    closeDialog() {
        this.$emit("contactFormClosed");
    },

    cleanForm() {
        this.contacto.documento = "";
        this.contacto.empresa = "";
        this.contacto.email = "";
        this.contacto.celular = "";
        this.contacto.mensaje = "";
        this.$refs.contactoOutForm.reset()
    },

    async loadEmpresas() {
      this.contacto.empresasLoading = true;
      const response = await axios.get(
        process.env.VUE_APP_MIX_BASE_API + "/datos-empresas",
        { params: { doc: this.contacto.documento } }
      );
      this.contacto.empresas = response.data;
      this.contacto.empresasLoading = false;
    },
  },
};
</script>
